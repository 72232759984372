import { Environment } from 'src/app/utils/config';;

export const environment: Environment = {
  production: true,
  apiUrl: '/api',
  oauthServerUrl: 'https://login.france-ioi.org',
  oauthClientId: '50',
  searchApiUrl: 'https://rcjrg5ofrfttrugg26cj2x3xwi0nowdv.lambda-url.eu-west-3.on.aws',
  forumServerUrl: 'wss://i4eb4aa6ol.execute-api.eu-west-3.amazonaws.com/tezos-prod',

  sentryDsn: 'https://6295834d69104f54b55cc0ebe4ada310@o1167067.ingest.sentry.io/6257761',

  defaultActivityId: '1',
  defaultSkillId: undefined, // disabled
  allUsersGroupId: '3',

  languages: [
    { tag: 'en', path: '/en/' },
  ],
  defaultTitle: 'Open Tezos',
  languageSpecificTitles: { },

  allowForcedToken: true,
  authType: 'cookies',

  itemPlatformId: 'algorea_tezos_backend',

  theme: 'default',
  featureFlags: {
    hideTaskTabs: [ 'progress' ],
  },

  /* paths to be matched must not have a trailing slash */
  redirects: {
    'home': { id: '1', path: [] },
    'blockchain-basics': { id: '1484719710020206758', path: [ '1' ] },
    'blockchain-basics/introduction': { id: '511308877223033964', path: [ '1', '1484719710020206758' ] },
    'blockchain-basics/main-properties': { id: '6133266482233120586', path: [ '1', '1484719710020206758' ] },
    'blockchain-basics/proof-of-work': { id: '2446909545349166564', path: [ '1', '1484719710020206758' ] },
    'blockchain-basics/other-consensuses': { id: '9067952639504500243', path: [ '1', '1484719710020206758' ] },
    'blockchain-basics/smart-contracts': { id: '306818700618954870', path: [ '1', '1484719710020206758' ] },
    'tezos-basics': { id: '3556096645042535892', path: [ '1' ] },
    'tezos-basics/introduction': { id: '5104730181775200910', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/nodes': { id: '8072870076479361175', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/liquid-proof-of-stake': { id: '1430573517761712635', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/operations': { id: '7102132444479298439', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/cli-and-rpc': { id: '1332828229711949831', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/governance-on-chain': { id: '8575774343547890709', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/history-of-amendments': { id: '5159110706379405990', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/economics-and-rewards': { id: '1889140410463787552', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/tezos-performances-and-costs': { id: '4144796058893710300', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/tickets': { id: '7602771500285647778', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/sapling': { id: '7398728134348655201', path: [ '1', '3556096645042535892' ] },
    'tezos-basics/test_networks': { id: '5386979617533281547', path: [ '1', '3556096645042535892' ] },
    'smart-contracts': { id: '8063809176983816760', path: [ '1' ] },
    'smart-contracts/simple-nft-contract-1': { id: '142054093987020957', path: [ '1', '8063809176983816760' ] },
    'smart-contracts/call-and-deploy': { id: '5165070026210178812', path: [ '1', '8063809176983816760' ] },
    'smart-contracts/simple-nft-contract-2': { id: '8660898465779467397', path: [ '1', '8063809176983816760' ] },
    'smart-contracts/simplified-contracts': { id: '3339574486413380326', path: [ '1', '8063809176983816760' ] },
    'smart-contracts/smart-contracts-concepts': { id: '4410301308910496574', path: [ '1', '8063809176983816760' ] },
    'smart-contracts/avoiding-flaws': { id: '725398684297594337', path: [ '1', '8063809176983816760' ] },
    'smart-contracts/oracles': { id: '6423484125139719620', path: [ '1', '8063809176983816760' ] },
    'deploy-a-node': { id: '748829836366992273', path: [ '1' ] },
    'deploy-a-node/introduction': { id: '2061881961608545731', path: [ '1', '748829836366992273' ] },
    'deploy-a-node/installation': { id: '8633391639700919318', path: [ '1', '748829836366992273' ] },
    'deploy-a-node/monitor-a-node': { id: '3464118957616571063', path: [ '1', '748829836366992273' ] },
    'deploy-a-node/networks': { id: '7300833418578909339', path: [ '1', '748829836366992273' ] },
    'best-practices': { id: '3088120201367716402', path: [ '1', '748829836366992273' ] },
    'deploy-a-node/to-go-further': { id: '1955860584480552882', path: [ '1', '748829836366992273' ] },
    'deploy-a-node/node-cluster': { id: '2913216627171869080', path: [ '1', '748829836366992273' ] },
    'explorer': { id: '1162172971053141543', path: [ '1' ] },
    'explorer/introduction': { id: '8137606270810820359', path: [ '1', '1162172971053141543' ] },
    'explorer/indexer-explained': { id: '6498650385868901310', path: [ '1', '1162172971053141543' ] },
    'explorer/available-tezos-indexers': { id: '7408542002878265390', path: [ '1', '1162172971053141543' ] },
    'explorer/tzstats-main-features': { id: '9050324182390508827', path: [ '1', '1162172971053141543' ] },
    'explorer/tzstats-smart-contract': { id: '6821425852694075356', path: [ '1', '1162172971053141543' ] },
    'explorer/private-indexer': { id: '2498842270696724921', path: [ '1', '1162172971053141543' ] },
    'archetype': { id: '8350475356762232940', path: [ '1' ] },
    'archetype/introduction': { id: '7490086065348943207', path: [ '1', '8350475356762232940' ] },
    'archetype/completium': { id: '5151527088760340742', path: [ '1', '8350475356762232940' ] },
    'archetype/raffle-example': { id: '4226490826137418171', path: [ '1', '8350475356762232940' ] },
    'archetype/raffle-example/contract': { id: '4441578161988980746', path: [ '1', '8350475356762232940', '4226490826137418171' ] },
    'archetype/raffle-example/test': { id: '5382369873700768042', path: [ '1', '8350475356762232940', '4226490826137418171' ] },
    'archetype/events': { id: '1795244593634229652', path: [ '1', '8350475356762232940' ] },
    'archetype/events/intro': { id: '5001505143773046457', path: [ '1', '8350475356762232940', '1795244593634229652' ] },
    'archetype/events/example': { id: '6390642275007105121', path: [ '1', '8350475356762232940', '1795244593634229652' ] },
    'archetype/events/dappexample': { id: '6725578813000070910', path: [ '1', '8350475356762232940', '1795244593634229652' ] },
    'archetype/templates': { id: '1418808898514093479', path: [ '1', '8350475356762232940' ] },
    'smartpy': { id: '2568077090277631143', path: [ '1' ] },
    'smartpy/introduction': { id: '2568077090277631143', path: [ '1' ] },
    'smartpy/installation': { id: '2568077090277631143', path: [ '1' ] },
    'smartpy/write-contract-smartpy': { id: '2568077090277631143', path: [ '1' ] },
    'ligo': { id: '5649666098758041047', path: [ '1' ] },
    'ligo/introduction': { id: '5884829458221269655', path: [ '1', '5649666098758041047' ] },
    'ligo/installation': { id: '6000598992528601986', path: [ '1', '5649666098758041047' ] },
    'ligo/write-contract-ligo': { id: '136747173741850403', path: [ '1', '5649666098758041047' ] },
    'ligo/write-contract-ligo/raffle-contract': { id: '3068498118789916406', path: [ '1', '5649666098758041047', '136747173741850403' ] },
    'ligo/write-contract-ligo/launch-raffle': { id: '8229388601931980232', path: [ '1', '5649666098758041047', '136747173741850403' ] },
    'ligo/write-contract-ligo/buy-ticket': { id: '2867809519343607359', path: [ '1', '5649666098758041047', '136747173741850403' ] },
    'ligo/write-contract-ligo/close-raffle': { id: '4626750091727789851', path: [ '1', '5649666098758041047', '136747173741850403' ] },
    'ligo/write-contract-ligo/refactoring': { id: '8575312775842593928', path: [ '1', '5649666098758041047', '136747173741850403' ] },
    'ligo/deploy-a-contract': { id: '8114907079756499684', path: [ '1', '5649666098758041047' ] },
    'ligo/unit-testing': { id: '1801982972615771871', path: [ '1', '5649666098758041047' ] },
    'ligo/examples': { id: '844869931882538176', path: [ '1', '5649666098758041047' ] },
    'ligo/take-away': { id: '8034080913767157333', path: [ '1', '5649666098758041047' ] },
    'michelson': { id: '121625509032500019', path: [ '1' ] },
    'michelson/introduction': { id: '4427630517142259420', path: [ '1', '121625509032500019' ] },
    'michelson/smart-contracts': { id: '139880861051615624', path: [ '1', '121625509032500019' ] },
    'michelson/tutorial': { id: '7889308214910545449', path: [ '1', '121625509032500019' ] },
    'michelson/examples': { id: '4350337431873328168', path: [ '1', '121625509032500019' ] },
    'michelson/instructions-reference': { id: '3672763659738015042', path: [ '1', '121625509032500019' ] },
    'michelson/take-away': { id: '1921096139861397267', path: [ '1', '121625509032500019' ] },
    'dapp': { id: '4885641064056262313', path: [ '1' ] },
    'dapp/introduction': { id: '3942236650512220527', path: [ '1', '4885641064056262313' ] },
    'dapp/taquito': { id: '897672650850654443', path: [ '1', '4885641064056262313' ] },
    'dapp/temple-wallet': { id: '7049586045827200544', path: [ '1', '4885641064056262313' ] },
    'dapp/deploy': { id: '6815141816925743171', path: [ '1', '4885641064056262313' ] },
    'dapp/deploy/introduction': { id: '8693121578752493105', path: [ '1', '4885641064056262313', '6815141816925743171' ] },
    'dapp/deploy/deploy-with-taquito': { id: '1427529772732540980', path: [ '1', '4885641064056262313', '6815141816925743171' ] },
    'dapp/deploy/deploy-with-chinstrap': { id: '8547373232136576818', path: [ '1', '4885641064056262313', '6815141816925743171' ] },
    'dapp/frontend-basics': { id: '4225806524703700190', path: [ '1', '4885641064056262313' ] },
    'dapp/frontend-advanced': { id: '4359705331991190430', path: [ '1', '4885641064056262313' ] },
    'dapp/indexers': { id: '617445331929990814', path: [ '1', '4885641064056262313' ] },
    'dapp/indexers/introduction': { id: '1354026336591096002', path: [ '1', '4885641064056262313', '617445331929990814' ] },
    'dapp/indexers/how_indexers_work': { id: '2612431650879057979', path: [ '1', '4885641064056262313', '617445331929990814' ] },
    'dapp/indexers/full_and_selective_indexers': { id: '5359901704633009289', path: [ '1', '4885641064056262313', '617445331929990814' ] },
    'dapp/indexers/tzkt': { id: '9148429477112359814', path: [ '1', '4885641064056262313', '617445331929990814' ] },
    'dapp/indexers/que_pasa': { id: '5931400048421950772', path: [ '1', '4885641064056262313', '617445331929990814' ] },
    'dapp/indexers/dip_dup_dappetizer': { id: '4018783615050009087', path: [ '1', '4885641064056262313', '617445331929990814' ] },
    'baking': { id: '2042465551629029432', path: [ '1' ] },
    'baking/introduction': { id: '7203376140340982269', path: [ '1', '2042465551629029432' ] },
    'baking/baking_explained': { id: '2249809382401329369', path: [ '1', '2042465551629029432' ] },
    'baking/reward': { id: '1513420159315897034', path: [ '1', '2042465551629029432' ] },
    'baking/risks': { id: '4458654628702809173', path: [ '1', '2042465551629029432' ] },
    'baking/delegating': { id: '3356688753351243473', path: [ '1', '2042465551629029432' ] },
    'baking/bakers_list': { id: '5650646804139142751', path: [ '1', '2042465551629029432' ] },
    'baking/cli-baker': { id: '7395197274397454607', path: [ '1', '2042465551629029432' ] },
    'baking/consensus-key': { id: '8650065240561545876', path: [ '1', '2042465551629029432' ] },
    'baking/persistent-baker': { id: '1360634960088493232', path: [ '1', '2042465551629029432' ] },
    'baking/baker-selection': { id: '188565837276863306', path: [ '1', '2042465551629029432' ] },
    'baking/glossary': { id: '1799681238670918560', path: [ '1', '2042465551629029432' ] },
    'defi': { id: '1110974181665431312', path: [ '1' ] },
    'defi/introduction': { id: '2572784150423562089', path: [ '1', '1110974181665431312' ] },
    'defi/token-standards': { id: '3350976424131897384', path: [ '1', '1110974181665431312' ] },
    'defi/dexs': { id: '5750237817002525774', path: [ '1', '1110974181665431312' ] },
    'defi/wrapped-assets': { id: '5127362107508222655', path: [ '1', '1110974181665431312' ] },
    'defi/cross-chain-swaps': { id: '4030012129390292618', path: [ '1', '1110974181665431312' ] },
    'defi/oracles': { id: '9198183239714570919', path: [ '1', '1110974181665431312' ] },
    'defi/stablecoins': { id: '1654340978249901638', path: [ '1', '1110974181665431312' ] },
    'defi/synthetics': { id: '8326866537597901674', path: [ '1', '1110974181665431312' ] },
    'defi/dao': { id: '6697710512388291833', path: [ '1', '1110974181665431312' ] },
    'defi/lending': { id: '4918951620888513437', path: [ '1', '1110974181665431312' ] },
    'defi/decentralized-fundraising': { id: '9057070784657003351', path: [ '1', '1110974181665431312' ] },
    'defi/rolling-safe': { id: '2818737320033178206', path: [ '1', '1110974181665431312' ] },
    'defi/rolling-safe/presentation': { id: '415630325582473605', path: [ '1', '1110974181665431312', '2818737320033178206' ] },
    'defi/rolling-safe/smart-contract': { id: '8431466845234219670', path: [ '1', '1110974181665431312', '2818737320033178206' ] },
    'gaming': { id: '4663138790040255629', path: [ '1' ] },
    'gaming/unity-sdk': { id: '1644886415853835821', path: [ '1', '4663138790040255629' ] },
    'gaming/unity-sdk/introduction': { id: '8008318084751763550', path: [ '1', '4663138790040255629', '1644886415853835821' ] },
    'gaming/unity-sdk/getting-started': { id: '4297556934663786406', path: [ '1', '4663138790040255629', '1644886415853835821' ] },
    'gaming/unity-sdk/api-documentation': { id: '4352780633918773069', path: [ '1', '4663138790040255629', '1644886415853835821' ] },
    'gaming/unity-sdk/inventory-sample-game': { id: '7424819803174678724', path: [ '1', '4663138790040255629', '1644886415853835821' ] },
    'gaming/unity-sdk/other-use-cases': { id: '7029225961900198603', path: [ '1', '4663138790040255629', '1644886415853835821' ] },
    'self-sovereign-identity': { id: '2636575147840514225', path: [ '1' ] },
    'self-sovereign-identity/introduction': { id: '7525650193051604267', path: [ '1', '2636575147840514225' ] },
    'self-sovereign-identity/challenges': { id: '8327974132171676989', path: [ '1', '2636575147840514225' ] },
    'self-sovereign-identity/development': { id: '437614341637193781', path: [ '1', '2636575147840514225' ] },
    'self-sovereign-identity/did': { id: '3088956522241519450', path: [ '1', '2636575147840514225' ] },
    'self-sovereign-identity/did-platforms': { id: '1117559525316187490', path: [ '1', '2636575147840514225' ] },
    'self-sovereign-identity/tezos-did': { id: '3414756715659120161', path: [ '1', '2636575147840514225' ] },
    'self-sovereign-identity/sources': { id: '3405382856664157860', path: [ '1', '2636575147840514225' ] },
    'formal-verification': { id: '6404454578206786601', path: [ '1' ] },
    'formal-verification/introduction': { id: '4226265829368957026', path: [ '1', '6404454578206786601' ] },
    'formal-verification/general': { id: '2127205211345390388', path: [ '1', '6404454578206786601' ] },
    'formal-verification/michocoq': { id: '8384806053459364476', path: [ '1', '6404454578206786601' ] },
    'formal-verification/modeling-theorem': { id: '7546320435714534603', path: [ '1', '6404454578206786601' ] },
    'private': { id: '1681401520504217883', path: [ '1' ] },
    'private/introduction': { id: '500769161978803788', path: [ '1', '1681401520504217883' ] },
    'private/installation': { id: '5036295883526401579', path: [ '1', '1681401520504217883' ] },
    'private/genesis': { id: '6326797744397549810', path: [ '1', '1681401520504217883' ] },
    'private/starting-blockchain': { id: '8129780895849088252', path: [ '1', '1681401520504217883' ] },
    'private/using-blockchain': { id: '6844937147484781090', path: [ '1', '1681401520504217883' ] },
    'private/vpn': { id: '32708437815487657', path: [ '1', '1681401520504217883' ] },
    'contribute': { id: '6848438477710581377', path: [ '1' ] },
    'contribute/introduction': { id: '6443529888250521001', path: [ '1', '6848438477710581377' ] },
    'contribute/report-issue': { id: '5892425287114056434', path: [ '1', '6848438477710581377' ] },
    'contribute/opentezos': { id: '640220422204944719', path: [ '1', '6848438477710581377' ] },
    'contribute/tezos-core': { id: '282771369415587327', path: [ '1', '6848438477710581377' ] },
    'contribute/grant': { id: '3989648293232966174', path: [ '1', '6848438477710581377' ] },
    'contribute/baker': { id: '2269214197826080407', path: [ '1', '6848438477710581377' ] },
  }
};

type Preset = 'example' | 'demo';
export const presets: Record<Preset, Partial<Environment>> = {
  example: {
    defaultActivityId: '1625159049301502151', // Motif Art
    defaultTitle: 'Example app',
    authType: 'tokens',
    itemPlatformId: 'Example',
  },
  demo: {
    defaultActivityId: '1352246428241737349', // SNT
    defaultTitle: 'Demo app'
  },
};

export function getPresetNameByOrigin(origin: string): Preset | null {
  switch (origin) {
    case 'http://example.algorea.org': return 'example';
    default: return null;
  }
}

